<template>
  <div>
    <HomepageHero class="max-w-6xl mx-auto" />
    <HomepageClientCard class="max-w-6xl mx-auto rounded-xl" />
    <HomepageExpertise class="max-w-6xl mx-auto" />
    <HomepageExplainer class="max-w-6xl mx-auto" />
    <HomepageTechnologies class="max-w-6xl mx-auto" />
    <HomepageServices class="max-w-6xl mx-auto" />
    <HomepageCTA class="max-w-6xl mx-auto" />
  </div>
</template>
