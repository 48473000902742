<template>
  <div
    class="rounded-3xl p-8"
    style="background: linear-gradient(264deg, #fcfaf3, #f4fbfe)"
  >
    <div class="container m-auto text-gray-500 md:px-12">
      <div class="items-center gap-12">
        <div class="">
          <h2 class="text-2xl font-bold text-gray-800 ">
            Our clients
          </h2>
          <p class="text-gray-600 ">
            We have already worked for +20 clients
          </p>
        </div>
        <div class="mt-8">
          <div
            class="-mx-6 flex flex-wrap gap-6 px-6 md:justify-between lg:gap-2"
          >
            <div class="flex items-center rounded-xl lg:p-4 lg:hover:shadow-lg cursor-pointer">
              <img src="/img/google.png" class="w-24 lg:w-28" alt="" />
            </div>

            <div class="flex items-center rounded-xl lg:p-4 lg:hover:shadow-lg cursor-pointer">
              <img src="/img/digi.png" class="w-16" alt="" />
            </div>

            <div class="flex items-center rounded-xl lg:p-4 lg:hover:shadow-lg cursor-pointer">
              <img src="/img/moe.png" class="w-28" alt="" />
            </div>

            <div class="flex items-center rounded-xl lg:p-4 lg:hover:shadow-lg cursor-pointer">
              <img src="/img/seekers.png" class="w-32" alt="" />
            </div>

            <div class="flex items-center rounded-xl lg:p-4 lg:hover:shadow-lg cursor-pointer">
              <img src="/img/fp.png" class="w-16" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
