<template>
  <section>
    <div class="py-16">
      <div class="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
        <div
          class="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-2 grid-rows-2"
        >
          <div
            class="border border-gray-100 rounded-3xl bg-white px-8 pt-12 shadow-2xl shadow-gray-600/10 sm:px-12 lg:px-8"
            v-for="item in stacks"
          >
            <div class="mb-12 space-y-4">
              <h3 class="text-2xl font-semibold text-gray-800">
                {{ item.h3 }}
              </h3>
              <p class="mb-6 text-gray-600 ">
                {{ item.p }}
              </p>
            </div>
            <img
              :src="item.img"
              class="ml-auto w-2/3 -mt-8 max-h-40"
              alt="illustration"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import uiDesign from "/img/ui-design.svg";
const stacks = [
  {
    icon: "mdi:application-brackets-outline",
    color: "text-blue-500",
    h3: "Custom Web-App Development",
    p: "Create custom software tailored for your unique needs, including front-end, and core back-end technology.",
    img: "/img/service.webp",
  },
  {
    icon: "mdi:ab-testing",
    color: "text-amber-500",
    h3: "QA and Testing",
    p: "Make your technology bulletproof, with manual and automated testing.",
    img: "/img/service3.webp",
  },
  {
    icon: "ic:outline-design-services",
    color: "text-green-700",
    h3: "UX/UI Design",
    p: "Create beautiful, pixel-perfect, and easy-to-use designs that delight your end users.",
    img: "/img/ui-design.svg"
  },
  {
    icon: "ic:outline-pinch",
    color: "text-violet-500",
    h3: "Platform and Infrastructure",
    p: "Ensure applications are secure, fault tolerant and highly available with our DevOps and Security engineers.",
    img: "/img/graphic.svg",
  },
];
</script>
