<template>
  <section class="text-gray-600 body-font">
    <div class="mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div
        class="mx-auto lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <h1 class="text-[48px] font-bold tracking-tight  mb-4 text-gray-900" style="font-family: Inter, sans-serif">You Dream It<br/>We Build It<br/>From <u>Idea to Launch</u> and Beyond
        </h1>
        <p class="mb-8 leading-relaxed">We go beyond code. Our comprehensive solutions span marketing tech, sales platforms, UX monitoring and ongoing maintenance to fully empower your business.</p>
        <div class="flex justify-center">
         <button class="btn btn-neutral rounded-full px-8">Get In Touch</button>
         <button class="btn ml-4 btn-outline border-gray-400 rounded-full px-8">Our Services</button>
        </div>
      </div>
      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        <img class="object-cover object-center rounded opacity-90" alt="hero" src="/img/team.svg">
      </div>
    </div>
  </section>
</template>