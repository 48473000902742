<template>

<div class="py-16">
  <div class="container m-auto space-y-8 px-6 text-gray-500 md:px-12 lg:px-20">
    <div class="flex items-center justify-center -space-x-2">
      <!-- <img
        loading="lazy"
        width="220"
        height="220"
        src="images/avatars/second_user.webp"
        alt="member photo"
        class="h-8 w-8 rounded-full object-cover"
      />
      <img
        loading="lazy"
        width="220"
        height="220"
        src="images/avatars/first_user.webp"
        alt="member photo"
        class="h-12 w-12 rounded-full object-cover"
      />
      <img
        loading="lazy"
        width="220"
        height="220"
        src="images/avatars/third_user.webp"
        alt="member photo"
        class="z-10 h-16 w-16 rounded-full object-cover"
      />
      <img
        loading="lazy"
        width="220"
        height="220"
        src="images/avatars/first_user.webp"
        alt="member photo"
        class="relative h-12 w-12 rounded-full object-cover"
      />
      <img
        loading="lazy"
        width="220"
        height="220"
        src="images/avatars/second_user.webp"
        alt="member photo"
        class="h-8 w-8 rounded-full object-cover"
      /> -->
    </div>
    <div class="m-auto space-y-6 md:w-8/12 lg:w-7/12">
      <h1 class="text-center text-4xl font-bold text-gray-800  md:text-5xl">Get a Free Quote now</h1>
      <p class="text-center text-xl text-gray-600 ">
        Lets build the next best thing together
      </p>
      <div class="flex flex-wrap justify-center gap-6">
        <a
            href="#"
            class="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
          >
            <span class="relative text-base font-semibold text-white "
              >Get Started</span
            >
          </a>
          <a
            href="#"
            class="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:border before:border-gray-200 before:bg-gray-50 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
          >
            <span
              class="relative text-base font-semibold text-primary "
              >FAQs</span
            >
        </a>
      </div>
    </div>
  </div>
</div>
                                    
</template>