<template>

  <div class="py-40">
    <div class="container m-auto px-6 text-gray-500 md:px-12">
      <div class="m-auto">
        <h1 class="text-center text-4xl font-bold text-gray-800 ">Why Us</h1>
        <p class="text-center mt-6 text-xl text-gray-600 ">
          We are a team of highly skilled developers and designers who are passionate about building the next best thing.<br/>
          More than just a software development company, we are your partners in success.<br/>
          Cook your ideas with us and we will serve you the best dish.
        </p>
       
      </div>
    </div>
  </div>
                                      
  </template>